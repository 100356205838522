import React, {useEffect, useState} from 'react';
import {Container, Content, Message} from 'rsuite';
import {Header} from "../header/header";
import {DnsSingle} from "../../pages/dns-master/dns-single";

import './dashboard.scss';
import {Loading} from "../loading/loading";

export const Dashboard = () => {

    const [state, setState] = useState({
        loading: false,
        error: '',
        login: '',
        password: '',
        data: [],
    })

    // установка в локальном хранилище доступов
    function setData(data) {
        if(Object.keys(data).length > 0) {
            localStorage.setItem('loginData', JSON.stringify(data));
            setState(prevState => ({
                ...prevState, login: data['number'], password: data['password'],
            }))
        } else {
            localStorage.clear();
            setState(prevState => ({
                ...prevState, login: '', password: ''
            }))
        }
    }

    // проверка данных логина при первой прогрузке
    useEffect(() => {
        const loginData = JSON.parse(localStorage.getItem('loginData'));
        if(loginData && typeof loginData['login'] !== 'undefined' && typeof  loginData['password'] !== 'undefined') {
            setData(loginData)
        } else {
            localStorage.clear();
            setState(prevState => ({
                ...prevState, login: '', password: ''
            }))
        }
    }, [])

    // получение данных пользователя
    function getData() {

        setState(prevState => ({
            ...prevState, loading: true, error: '', data: []
        }))

        fetch('https://enum.ukrnames.ua/enum/backend/get-data.php', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                number: state.login,
                password: state.password,
            })
        })
            .then(response => response.json())
            .then(response => {
                if (response.error) {
                    setState(prevState => ({
                        ...prevState, loading: false, error: 'Помилка отримання даних'
                    }))
                } else {
                    setState(prevState => ({
                        ...prevState, loading: false, error: '', data: response
                    }))
                }
            })
            .catch(() => {
                setState(prevState => ({
                    ...prevState, loading: false, error: 'Помилка отримання даних'
                }))
            });
    }

    // при изменении данных пользователя
    useEffect(() => {
        if(state.login.length > 0) {
            getData()
        }
    }, [state.login])

    return(
        <Container>
            <Header state={state} setData={setData}/>
            <Content>
                {state.login.length > 0 && state.password.length > 0 ?
                    <div>
                        <h1>Ваш номер +380.{state.login.slice(7,)}</h1>
                        {state.error.length > 0 ?
                            <Message
                                children={state.error}
                                className='mt15'
                                type="error"
                            /> :
                            <div className='mt15'>
                                <Loading isLoading={state.loading} alreadyLoaded={false} width='100%'>
                                    <DnsSingle data={state.data} getData={getData}/>
                                </Loading>
                            </div>
                        }
                    </div> :
                    <p>Щоб почати користуватися сервісом, потрібно авторизуватись</p>
                }
            </Content>
        </Container>
    )
}