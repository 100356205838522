import React, {useState, useEffect} from 'react';
import {Input, Badge, SelectPicker} from 'rsuite';

export const DnsAddRecordNaptr = ({handleData}) => {

    // части данных поля data
    const [inputs, setInputs] = useState({
        service: 'sip',
        replacement: '',
        order: '100',
        preference: '10',
        flags: 's',
    })

    // placeholders всех полей data
    const placeholders = {
        order: '100',
        preference: '10',
    }

    // placeholders для полей services
    const placeholdersServices = {
        'sip': 'sip',
        'mailto': 'mailto',
        'iban': 'iban',
        'usdt-trc20': 'usdt-trc20',
        'usdt-bep20': 'usdt-bep20',
        'usdt-erc20': 'usdt-erc20',
        'usdt-solana': 'usdt-solana',
        'usdt-polygon': 'usdt-polygon',
    }

    // placeholders для полей replacement
    const placeholdersReplacement = {
        'sip': 'holder sip',
        'mailto': 'holder mailto',
        'iban': 'holder iban',
        'usdt-trc20': 'holder usdt-trc20',
        'usdt-bep20': 'holder usdt-bep20',
        'usdt-erc20': 'holder usdt-erc20',
        'usdt-solana': 'holder usdt-solana',
        'usdt-polygon': 'holder usdt-polygon',
    }

    // варианты поля flags для дропдауна
    const flags = ['s', 'a', 'u', 'p'].map(item => ({ label: item, value: item }))

    // варианты поля services для дропдауна
    const services = ['sip', 'mailto', 'iban', 'usdt-trc20', 'usdt-bep20', 'usdt-erc20', 'usdt-solana', 'usdt-polygon'].map(item => ({ label: placeholdersServices[item], value: item }))

    // изменение одного из полей data
    function handleInput(e, inputName) {
        setInputs(prevState => {
            return {...prevState, [inputName]: e}
        })
    }

    // при изменении одного из полей data
    useEffect(() => {
        const data = `${inputs.order} ${inputs.preference} "${inputs.flags}" "E2U+${inputs.service}" "!^.*$!${inputs.service}:${inputs.replacement}!"`
        handleData(data, 'data')
    }, [inputs])

    return (
        <>
            {Object.keys(inputs).map((input, index) => {
                return(
                    <div key={index} className={`dns-input dns-add-record-data-${input} dns-add-record-${input}`}>
                        <Badge content={input} color='blue'>
                            {input === 'flags' || input === 'service' ?
                                <SelectPicker
                                    data={input === 'flags' ? flags : services}
                                    defaultValue={inputs[input]}
                                    searchable={false}
                                    cleanable={false}
                                    onChange={(e) => handleInput(e, input)}
                                /> :
                                <Input
                                    onChange={(e) => handleInput(e, input)}
                                    type={['order', 'preference'].includes(input) ? 'number' : 'text'}
                                    min={0}
                                    max={65535}
                                    size='md'
                                    placeholder={input === 'replacement' ? placeholdersReplacement[inputs.service] : placeholders[input]}
                                    value={inputs[input]}
                                />
                            }
                        </Badge>
                    </div>
                )})
            }
        </>
    )

}