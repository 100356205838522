import React, {useState} from 'react';
import {Input, Badge, Button, Message, Modal} from 'rsuite';
import {Loading} from '../../components/loading/loading';

import {DnsAddRecordNaptr} from "./dns-add-record-naptr";

export const DnsAddRecord = ({toggle, domain, open = false}) => {

    const [state, setState] = useState({
        loading: false,
        error: '',
        success: '',
        type: 'naptr',
    })

    // список возможных полей
    const [inputs, setInputs] = useState({
        data : '',
        comment: '',
    })

    // список возможных полей
    const placeholders = {
        data : 'some data',
        comment: 'some comment',
    }

    // сохранение инпута
    const handleInput = (e, inputName) => {
        setInputs(prevState => {
            return {...prevState, [inputName]: e}
        })
    }

    // очистка инпутов
    const clearInputs = () => {
        setInputs(prevState => {
            return {...prevState,
                data : '',
                comment : '',
            }})
    }

    // запрос на добавления записи
    const addRecord = () => {

        setState(prevState => {
            return {...prevState, loading: true, error: '', success: ''}
        })

        let url = `https://enum.ukrnames.ua/enum/backend/manage-data.php?command=add&domain=${domain}`;
        for (const key in inputs) {
            if (inputs.hasOwnProperty(key) && inputs[key] !== '') {
                const value = inputs[key]
                url += `&${key}=${encodeURIComponent(value)}`
            }
        }

        const loginData = JSON.parse(localStorage.getItem('loginData'))
        url += `&number=${loginData['number']}&password=${loginData['password']}`

        fetch(url, {
            method: 'GET',
            header: {
                'Accept': 'application/json',
            }
        })
            .then(response => response.json())
            .then(response => {
                if (response.error) {
                    setState(prevState => {
                        return {...prevState, loading: false, error: 'Помилка: ' + response.error}
                    })
                } else {
                    setState(prevState => {
                        return {...prevState, loading: false, success: 'Запис успішно додано'}
                    })
                    setTimeout(() => closeModal(true), 1000)
                }
            })
            .catch(() => {
                setState(prevState => {
                    return {...prevState, loading: false, error: 'Помилка запиту'}
                })
            });
    }

    // при закрытии модального окна
    const closeModal = (needsUpdate = false) => {
        toggle(needsUpdate)
        setState(prevState => {
            return { ...prevState, error: '', success: ''}
        })
        clearInputs()
    }

    return (
        <Modal open={open} onClose={closeModal} overflow={false} size='xs'>
            <Modal.Header>
                <Modal.Title>Додавання NAPTR запису</Modal.Title>
            </Modal.Header>

            <Loading isLoading={state.loading} alreadyLoaded={true}>

                <Modal.Body>

                    {state.type.length > 0 ?
                        <>
                            <div className='dns-add-record-body'>

                                {state.error.length > 0 ? <Message type='error' className='mb20'>{state.error}</Message> : null}
                                {state.success.length > 0 ? <Message type='success' className='mb20'>{state.success}</Message> : null}

                                <div className={`dns-input-wrap`}>
                                    {Object.keys(inputs).map((input, index) => {
                                        if (input === 'data') return (<DnsAddRecordNaptr key={index} handleData={handleInput}/>)
                                        return (
                                            <div key={index} className={`dns-input dns-add-record-${state.type}-${input} dns-add-record-${input}`}>
                                                <Badge content={input} color='blue'>
                                                    <Input
                                                        onChange={(e) => handleInput(e, input)}
                                                        type={['mx_priority', 'refresh', 'retry', 'minimum', 'ttl'].includes(input) ? 'number' : 'text'}
                                                        size='md'
                                                        placeholder={placeholders[input]}
                                                        as={(state.type === 'txt' && input === 'data') || input === 'comment' ? 'textarea' : 'input'}
                                                        value={inputs[input]}
                                                    />
                                                </Badge>
                                            </div>
                                        )})
                                    }
                                </div>
                            </div>
                        </> : null
                    }

                </Modal.Body>

            </Loading>

            {state.type.length > 0 ?
                <Modal.Footer>
                    <Button
                        children='Додати'
                        appearance='primary'
                        color='green'
                        onClick={addRecord}
                    />
                    <Button
                        children='Закрити'
                        onClick={closeModal}
                        appearance='default'
                    />
                </Modal.Footer> : null
            }

        </Modal>
    )

}