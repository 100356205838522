import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import {Header as HeaderRSuite, Navbar, Nav, Button, Stack} from 'rsuite';
import {Auth} from "../auth/auth";

import './header.scss';

import logo from "../../images/logo-dark.svg";

export const Header = ({state, setData}) => {

    const coreUrl = process.env.REACT_APP_ROOT
    const [auth, setAuth] = useState({
        modal: ''
    })

    // изменение модального окна
    function handleModal(modal = '', logged = false, data = []) {
        setAuth(prevState => ({
            ...prevState, modal: modal
        }))
        // если после изменения статуса модального оукна произошла успешная авторизация
        if(logged) setData(data)
    }

    return(
        <HeaderRSuite>
            <Navbar>
                <Navbar.Brand as={NavLink} to={coreUrl}>
                    <img src={logo} alt="Ukrnames logo" />
                </Navbar.Brand>
                <Nav pullRight>
                    <Stack className="h-logout desktop">
                        {state.login.length > 0 && state.password.length > 0 ?
                            <Button
                                onClick={() => handleModal('', true, [])}
                                children='Вихід'
                                color='blue'
                                appearance="primary"
                            /> :
                            <>
                                <Stack>
                                    <Button
                                        onClick={() => handleModal('reg')}
                                        children='Реєстрація'
                                        color='green'
                                        appearance="primary"
                                    />
                                    <Button
                                        onClick={() => handleModal('log')}
                                        children='Вхід'
                                        color='blue'
                                        appearance="primary"
                                        className='ml5'
                                    />
                                </Stack>
                            </>
                        }
                    </Stack>
                </Nav>
            </Navbar>
            {auth.modal.length > 0 ?
                <Auth handleClose={handleModal} modal={auth.modal}/> : null
            }
        </HeaderRSuite>
    )
}