import React, {useState} from 'react';
import {Input, Button, Message, Badge, Tag} from 'rsuite';
import {Loading} from "../../components/loading/loading";
import {DnsAddRecord} from "./dns-add-record";
import {DnsSingleDataNaptr} from "./dns-single-data-naptr";

import './dns-master.scss';

export const DnsSingle = ({data, getData}) => {

    let [state, setState] = useState({
        loading: false,
        error: '',
        success: '',
        edit: false,
        add: false,
        currentEdit: '',
    })

    // параметры модального окна добавления записи
    const [modalState, setModalState] = useState({
        open: false,
        domainAdded: false // переключатель при изменении которого обновляются общие данные
    })

    // параметры для инпутов со значением возможности редактирования
    let paramsOrder = {
        'data': true,
        'comment': true,
    }

    // параметры, которые можно обновить для текущей записи
    let [paramsToUpdate, setParamsToUpdate] = useState({
        data: '',
        comment: '',
    })

    // вкл/выкл модального окна добавления новой записи
    const handleToggleModal = (needUpdate = false) => {
        setModalState(prevState => {
            return {...prevState, open: !modalState.open}
        })
        setState(prevState => ({
            ...prevState, error: '', success: ''
        }))
        // если при закрытии модального окна данные были обновлены и необходима перегрузка контента
        if(needUpdate === true) {
            getData()
        }
    }

    // удаление одной записи домена
    const deleteRecord = (id) => {editData('delete', {'id':id})}

    // вкл/выкл режима редактирования
    const editRecord = (index, dataRecord = false) => {

        // если данных нет - значит нажата кнопка отмены
        if(dataRecord === false){
            setState(prevState => {
                return {...prevState, currentEdit: '', error: '', success: ''}
            })
            // установка временных параметров для редактирования
            setParamsToUpdate(prevState => {
                return {
                    ...prevState,
                    data: dataRecord.data,
                    comment: dataRecord.comment,
                }
            })
            return
        }

        // установка временных параметров для редактирования
        setParamsToUpdate(prevState => {
            return {
                ...prevState,
                data: dataRecord.data,
                comment: dataRecord.comment,
            }
        })

        // переключение режима редактирования
        setState(prevState => {
            return {...prevState, currentEdit: dataRecord.id}
        })
    }

    // сохранение редактируемых полей записи
    const saveEdit = (index) => {

        // данные для обновления, id будет в любом случае
        let dataToUpdate = { id: data[index].id };

        // вычитание всех совпадений из редактируемых параметров параметров по умолчанию
        for (const key in paramsToUpdate) {
            if (paramsToUpdate.hasOwnProperty(key) && paramsToUpdate[key] !== data[index][key]) {
                dataToUpdate[key] = paramsToUpdate[key];
            }
        }

        // если данные не были изменены
        if(Object.keys(dataToUpdate).length === 1) {
            // прокрутка к верху страницы
            let scrollDiv = document.getElementById('root').offsetTop;
            window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
            setState(prevState => {
                return {...prevState, error: 'Необхідно змінити хоча б одне поле', success: ''}
            })
            return
        }

        // из полученной разницы будет построен урл
        editData('update', dataToUpdate)

    }

    // установка состояния для редактируемых параметров
    const handleInput = (paramName, e) => {
        setParamsToUpdate(prevState => {
            return {...prevState, [paramName]: e}
        })
    }

    // редактирование данных (удаление/обновлене)
    const editData = (command, commandData) => {

        setState(prevState => {
            return {...prevState, loading: true, error: '', success: ''}
        })

        let url = 'https://enum.ukrnames.ua/enum/backend/manage-data.php?command=' + command;

        for (const key in commandData) {
            if (commandData.hasOwnProperty(key)) {
                const value = commandData[key];
                url += `&${key}=${encodeURIComponent(value)}`;
            }
        }

        const loginData = JSON.parse(localStorage.getItem('loginData'))
        url += `&number=${loginData['number']}&password=${loginData['password']}`

        fetch(url, {
            method: 'GET',
            header: {
                'Accept': 'application/json',
            }
        })
            .then(response => response.json())
            .then(response => {
                // прокрутка к верху страницы
                let scrollDiv = document.getElementById('root').offsetTop;
                window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
                if (response.error) {
                    setState(prevState => {
                        return {...prevState, loading: false, error: 'Помилка: ' + response.error}
                    })
                } else {
                    setState(prevState => {
                        return {...prevState, loading: false}
                    })

                    switch(command) {
                        case 'delete':
                            setState(prevState => {
                                return {...prevState, success: 'Запис успішно видалено'}
                            })
                            break
                        case 'update':
                            setState(prevState => {
                                return {...prevState, success: 'Запис успішно змінено', currentEdit: ''}
                            })
                            break
                        default:
                            break
                    }
                    setTimeout(() => getData(), 1000)
                }
            })
            .catch(() => {
                // прокрутка к верху страницы
                let scrollDiv = document.getElementById('root').offsetTop;
                window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
                setState(prevState => {
                    return {...prevState, loading: false, error: 'Помилка запиту'}
                })
            });

    }

    return (
        <Loading isLoading={state.loading} alreadyLoaded={false} width='100%' size='large'>

            {typeof data['domain'] !== 'undefined' || data.length === 0 ?
                <div>
                    <p className='mb15'>Крім базових SOA та NS записів записи типу NAPTR ще не створені</p>
                    <div className='dns-single'>
                        <div className='dns-single-title'>
                            <h2>{data['domain']}</h2>
                            <Button
                                children='Додати запис'
                                appearance='primary'
                                color='violet'
                                onClick={handleToggleModal}
                                disabled={state.currentEdit.length > 0}
                            />
                        </div>
                    </div>
                    <DnsAddRecord toggle={handleToggleModal} domain={data['domain']} open={modalState.open}/>
                </div> :
                <>
                    <div className='dns-single'>

                        {state.error.length > 0 ? <Message children={state.error} className='mb15' type='error'/> : null }
                        {state.success.length > 0 ? <Message children={state.success} className='mb15' closable type='success'/> : null }

                        <div className='dns-single-title'>
                            <h2>{data[0]['domain']}</h2>
                            <Button
                                children='Додати запис'
                                appearance='primary'
                                color='violet'
                                onClick={handleToggleModal}
                                disabled={state.currentEdit.length > 0}
                            />
                        </div>

                        <p className="sub-info">(Записів: {data.length})</p>

                        {data.map((record, index) => {

                            const currentTime = new Date().valueOf() // текущая дата
                            const expiryTime = new Date(record['last_update_date']).valueOf() // дата последнего обновления
                            const diff = (currentTime - expiryTime) / 1000 // разница в секундах
                            const newRecord = Math.abs(diff) < 180 // определение свежая ли запись (создана за последние 3 минуты)

                            return (
                                <div key={index} className={`dns-single-line dns-input-${data[index].type} ${newRecord ? 'dns-single-line-new' : ''}`}>

                                    <div className='dns-single-subtitle'>
                                        <div>Тип: <Tag color='violet'>NAPTR</Tag></div>
                                        <div className='empty'/>
                                        {state.currentEdit === data[index].id ?
                                            <>
                                                <Button
                                                    appearance='default'
                                                    children='Відміна'
                                                    onClick={() => editRecord(index)}
                                                />
                                                <Button
                                                    appearance='primary'
                                                    children='Зберегти'
                                                    color='green'
                                                    onClick={() => saveEdit(index)}
                                                />
                                            </> :
                                            <Button
                                                children='Редагувати'
                                                appearance='primary'
                                                color='orange'
                                                onClick={() => editRecord(index, data[index])}
                                                disabled={state.currentEdit.length > 0}
                                            />
                                        }
                                        <Button
                                            children='Видалити'
                                            appearance='primary'
                                            color='red'
                                            onClick={() => deleteRecord(data[index].id)}
                                            disabled={state.currentEdit.length > 0}
                                        />
                                    </div>

                                    <div className='dns-input-wrap'>
                                        {Object.keys(paramsOrder).map((paramName, indexKey) => {

                                            // либо данные, если они есть у текущего поля
                                            let inputValue = data[index][paramName] || ''
                                            let greenBorder = false

                                            // если включён режим редактирования и текущий id совпадает с id редактируемой записи
                                            // и текущий параметр может быть редактируемым
                                            if(state.currentEdit.length > 0 && data[index].id === state.currentEdit && paramsOrder[paramName]) {
                                                // значение инпута приравнивается ко временной переменной
                                                inputValue = paramsToUpdate[paramName] || ''
                                                // зелёная рамка для изменённых значений
                                                greenBorder = data[index][paramName] !== paramsToUpdate[paramName]
                                            }

                                            if(paramName === 'data')
                                                return <DnsSingleDataNaptr
                                                    key={indexKey}
                                                    inputData={inputValue}
                                                    handleData={handleInput}
                                                    greenBorder={greenBorder}
                                                    currentEdit={state.currentEdit}
                                                    disabled={(!(state.currentEdit === data[index].id && paramsOrder[paramName]))}
                                                />

                                            return(
                                                <div key={indexKey} className={`dns-input dns-input-${data[index].type}-${paramName} dns-input-${paramName}`}>
                                                    <Badge content={paramName} color='blue'>
                                                        <Input
                                                            onChange={(e) => handleInput(paramName, e)}
                                                            size='md'
                                                            type={['mx_priority', 'refresh', 'retry', 'minimum', 'ttl'].includes(paramName) ? 'number' : 'text'}
                                                            className={`${greenBorder ? 'green-bordered' : ''}`}
                                                            as={(data[index].type === 'txt' && paramName === 'data' || paramName === 'comment') ? 'textarea' : 'input'}
                                                            value={inputValue}
                                                            disabled={(!(state.currentEdit === data[index].id && paramsOrder[paramName]))}
                                                        />
                                                    </Badge>
                                                </div>
                                            )
                                        })}
                                    </div>

                                </div>
                            )})
                        }

                    </div>

                    <DnsAddRecord toggle={handleToggleModal} domain={data[0].domain} open={modalState.open}/>
                </>
            }

        </Loading>
    )

}