import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import { CustomProvider } from 'rsuite';
import {ukUA} from './documentation/uk_UA';
import './index.css';
import {App} from './App';
import reportWebVitals from './reportWebVitals';

// React.StrictMode улучшает дебаг приложения
// проверяет на наличие ошибок, предупреждает о возможных проблемах итд.
ReactDOM.render(
  <React.StrictMode>
      <CustomProvider locale={ukUA}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
      </CustomProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
