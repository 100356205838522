var Calendar = {
    sunday: 'Нд',
    monday: 'Пн',
    tuesday: 'Вт',
    wednesday: 'Ср',
    thursday: 'Чт',
    friday: 'Пт',
    saturday: 'Сб',
    ok: 'ОК',
    today: 'Сьогодні',
    yesterday: 'Вчора',
    hours: 'Годин',
    minutes: 'Хвилин',
    seconds: 'Секунд',
    formattedMonthPattern: 'MMM, yyyy',
    formattedDayPattern: 'dd MMM yyyy',
};
export const ukUA = {
    common: {
        loading: 'Завантаження...',
        emptyMessage: 'Дані не знайдені'
    },
    Plaintext: {
        unfilled: 'незаповненою',
        notSelected: 'Не вибрано',
        notUploaded: 'Не завантажено'
    },
    Pagination: {
        more: 'Більше',
        prev: 'Попередня',
        next: 'Наступна',
        first: 'Перша',
        last: 'Остання',
        limit: '{0} / сторінок',
        total: 'всього: {0}',
        skip: 'Перейти на {0}'
    },
    Calendar: Calendar,
    DatePicker: Calendar,
    DateRangePicker: Calendar,
    Picker: {
        noResultsText: 'Результати не знайдені',
        placeholder: 'Вибрати',
        searchPlaceholder: 'Пошук',
        checkAll: 'Усі'
    },
    InputPicker: {
        newItem: 'Новий',
        createOption: 'Створити опцію "{0}"'
    },
    Uploader: {
        inited: 'Початок',
        progress: 'Завантаження',
        error: 'Помилка',
        complete: 'Завершено',
        emptyFile: 'Пусто',
        upload: 'Завантажити'
    },
    CloseButton: {
        closeLabel: 'несправність'
    },
    Breadcrumb: {
        expandText: 'Показати шлях'
    },
    Toggle: {
        on: 'Увімк',
        off: 'Вимк'
    }
};