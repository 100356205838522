import React from 'react';
import 'rsuite/dist/rsuite-no-reset.min.css';
import {Dashboard} from "./components/dashboard/dashboard";

import './app.scss';

export const App = () => {
    return (
        <Dashboard/>
    )
}