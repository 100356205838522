import React, {useState} from 'react';
import {Modal, Input, InputGroup, Button, Message, Stack} from 'rsuite';

import './modals.scss';

export const Auth = ({handleClose, modal}) => {

    const [state, setState] = useState({
        loading: false,
        error: '',
        number: '',
        password: ''
    })

    // изменение номера
    function changeNumber(stringNumber, backspace = false) {

        if(stringNumber.length < 2) {
            setState(prevState => ({
                ...prevState, number: '('
            }))
            return
        }

        // номер исключительно из цифр, удаление всех символов, кроме цифр
        let digitNumber = stringNumber.replace(/\D/g, '')
        let result // для итогового результата

        if(backspace) digitNumber = digitNumber.slice(0,-1)

        // в зависимости от длины формируется формат номера
        if(digitNumber.length < 2) {
            result = `(${digitNumber.slice(0, 2)}`
        } else if(digitNumber.length === 2) {
            result = `(${digitNumber.slice(0,2)}) `
        } else if(digitNumber.length < 5) {
            result = `(${digitNumber.slice(0,2)}) ${digitNumber.slice(2, 5)}`;
        } else if(digitNumber.length === 5) {
            result = `(${digitNumber.slice(0,2)}) ${digitNumber.slice(2, 5)}-`;
        } else if(digitNumber.length < 7) {
            result = `(${digitNumber.slice(0,2)}) ${digitNumber.slice(2, 5)}-${digitNumber.slice(5, 7)}`;
        } else if(digitNumber.length === 7) {
            result = `(${digitNumber.slice(0,2)}) ${digitNumber.slice(2, 5)}-${digitNumber.slice(5, 7)}-`;
        } else result = `(${digitNumber.slice(0,2)}) ${digitNumber.slice(2, 5)}-${digitNumber.slice(5, 7)}-${digitNumber.slice(7, 9)}`;

        setState(prevState => ({
            ...prevState, number: result
        }))
    }

    // нажатие клавиши по инпуту номера
    function handleNumber(e) {
        if(e.key === 'Backspace') {
            // получение последнего символа
            let lastChar = e.target.value.slice(-1);
            let isLastCharDigit = isNaN(parseInt(lastChar));
            // если последний символ не цифра
            if(isLastCharDigit) {
                // предотвращение базового changeNumber
                e.preventDefault()
                changeNumber(e.target.value, true)
            }
        }
        if(e.key === 'Enter' && modal === 'reg' && state.number.length === 14) register()
        if(e.key === 'Enter' && modal === 'log' && state.number.length === 14 && state.password.length > 0) login()
    }

    // изменение пароля
    function changePassword(e) {
        setState(prevState => ({
            ...prevState, password: e
        }))
    }

    // нажатие на Ентер при вводе пароля
    function handlePassword(e) {
        if(e.key === 'Enter' && modal === 'log' && state.number.length === 14 && state.password.length > 0) login()
    }

    // при фокусе на инпуте - отображение базового значения инпута (0
    function selectInput() {
        if(state.number.length < 1) {
            setState(prevState => ({
                ...prevState, number: '('
            }))
        }
    }

    // регистрация нового пользователя
    function register() {

        setState(prevState => ({
            ...prevState, loading: true, error: ''
        }))

        // только цифры номера
        let number = state.number.replace(/\D/g, '')
        let numberUrl = `%2B380.${number}`

        fetch('https://enum.ukrnames.ua/enum/backend/register.php', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                number: numberUrl,
            })
        })
            .then(response => response.json())
            .then(response => {
                if (response.error) {
                    setState(prevState => ({
                        ...prevState, loading: false, error: 'Помилка реєстрації'
                    }))
                } else {
                    setState(prevState => ({
                        ...prevState, loading: false, error: ''
                    }))
                    // в случае успешной регистрации - открытие модального окна авторизации
                    handleClose('log')
                }
            })
            .catch(() => {
                setState(prevState => ({
                    ...prevState, loading: false, error: 'Помилка реєстрації'
                }))
            });

    }

    // авторизация
    function login() {
        setState(prevState => ({
            ...prevState, loading: true, error: ''
        }))

        // только цифры номера
        let number = state.number.replace(/\D/g, '')
        let numberUrl = `%2B380.${number}`

        fetch('https://enum.ukrnames.ua/enum/backend/login.php', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                number: numberUrl,
                password: state.password,
            })
        })
            .then(response => response.json())
            .then(response => {
                if (response.error) {
                    setState(prevState => ({
                        ...prevState, loading: false, error: 'Помилка авторизації'
                    }))
                } else {
                    setState(prevState => ({
                        ...prevState, loading: false, error: ''
                    }))
                    // в случае успешной регистрации - открытие модального окна авторизации
                    handleClose('', true, response)
                }
            })
            .catch(() => {
                setState(prevState => ({
                    ...prevState, loading: false, error: 'Помилка авторизації'
                }))
            });
    }

    return(
        <Modal backdrop={true} keyboard={false} open={true} onClose={() => handleClose('')} size={modal === 'reg' ? 350 : 270}>

                <Modal.Header>
                    <Modal.Title>{`${modal === 'reg' ? 'Реєстрація нового користувача' : 'Вхід'}`}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Stack className='pt5'>
                        <InputGroup>
                            <InputGroup.Addon>+380</InputGroup.Addon>
                            <Input
                                type="text"
                                onChange={(e) => changeNumber(e)}
                                onKeyDown={(e) => handleNumber(e)}
                                onSelect={selectInput}
                                value={state.number}
                                placeholder="(**) ***-**-**"
                                maxLength="14"
                                required={true}
                            />
                        </InputGroup>
                        {modal === 'reg' ?
                            <Button
                                appearance="primary"
                                children='Реєстрація'
                                loading={state.loading}
                                onClick={register}
                                color='green'
                                disabled={state.number.length !== 14 || state.loading}
                            /> : null
                        }
                    </Stack>

                    {modal === 'log' ?
                        <Stack className='mt15'>
                            <Input
                                type="text"
                                onChange={(e) => changePassword(e)}
                                onKeyDown={(e) => handlePassword(e)}
                                value={state.password}
                                placeholder="Пароль"
                                required={true}
                            />
                            <Button
                                appearance="primary"
                                children='Вхід'
                                loading={state.loading}
                                onClick={login}
                                color='blue'
                                disabled={state.number.length !== 14 || state.password.length === 0 || state.loading}
                            />
                        </Stack> : null
                    }

                    {state.error.length > 0 ?
                        <Message
                            children={state.error}
                            className='mt15'
                            type="error"
                        /> : null
                    }

                </Modal.Body>

        </Modal>
    )
}