import React, {useState, useEffect} from 'react';
import {Input, Badge, SelectPicker, IconButton, Whisper, Tooltip} from 'rsuite';

import {FaArrowCircleRight} from "react-icons/fa";
import {BiCopy} from 'react-icons/bi';

export const DnsSingleDataNaptr = ({inputData, handleData, greenBorder, disabled, currentEdit}) => {

    let matches = inputData.match(/(\d+) (\d+) "([saup])" "E2U\+([\w-]+)" "!\^.*\$!([\w-]+):(.*?)!"/u);

    // части данных поля data
    const [inputs, setInputs] = useState({
        service: matches[4] || '',
        replacement: matches[6] || '',
        order: matches[1] || '',
        preference: matches[2] || '',
        flags: matches[3] || '',
    })

    const [copy, setCopy] = useState('') // replacement, который только что скопировано

    const [data, setData] = useState('')

    // placeholders для полей services
    const placeholdersServices = {
        'sip': 'sip',
        'mailto': 'mailto',
        'iban': 'iban',
        'usdt-trc20': 'usdt-trc20',
        'usdt-bep20': 'usdt-bep20',
        'usdt-erc20': 'usdt-erc20',
        'usdt-solana': 'usdt-solana',
        'usdt-polygon': 'usdt-polygon',
    }

    // варианты поля flags для дропдауна
    const flags = ['s', 'a', 'u', 'p'].map(item => ({ label: item, value: item }))

    // варианты поля services для дропдауна
    const services = ['sip', 'mailto', 'iban', 'usdt-trc20', 'usdt-bep20', 'usdt-erc20', 'usdt-solana', 'usdt-polygon'].map(item => ({ label: placeholdersServices[item], value: item }))

    // изменение одного из полей data
    function handleInput(e, inputName) {
        setInputs(prevState => {
            return {...prevState, [inputName]: e}
        })
    }

    // при первой прогрузки контента
    useEffect(() => {
        if(currentEdit.length === 0) {
            // разбитие строки на части для отдельных полей
            let matches = inputData.match(/(\d+) (\d+) "([saup])" "E2U\+([\w-]+)" "!\^.*\$!([\w-]+):(.*?)!"/u);
            setInputs(prevState => ({
                ...prevState,
                order: matches[1],
                preference: matches[2],
                flags: matches[3],
                service: matches[4],
                replacement: matches[6]
            }))
            const resultInput = `${matches[1]} ${matches[2]} "${matches[3]}" "E2U+${matches[4]}" "!^.*$!${matches[4]}:${matches[6]}!"`
            setData(resultInput)
            handleData('data', resultInput)
        }
    }, [currentEdit])

    // при изменении одного из полей data
    useEffect(() => {
        const resultInput = `${inputs.order} ${inputs.preference} "${inputs.flags}" "E2U+${inputs.service}" "!^.*$!${inputs.service}:${inputs.replacement}!"`
        setData(resultInput)
        handleData('data', resultInput)
    }, [inputs])

    // скопирвать текст
    const copyText = (text) => {
        // копирование текста
        navigator.clipboard.writeText(text)
        // изменение текста на "Скопировано"
        setCopy(text)
        // через 3 секунды сброс текста "Скопировано"
        setTimeout(() => setCopy(''), 3000)
    }

    return (
        <>
            {Object.keys(inputs).map((input, index) => {
                return(
                    <React.Fragment key={input}>
                        <div key={index} className={`dns-input dns-input-data-naptr-${input}`}>
                            <Badge content={input} color='blue'>
                                {input === 'flags' || input === 'service' ?
                                    <>
                                        <SelectPicker
                                            data={input === 'flags' ? flags : services}
                                            defaultValue={inputs[input]}
                                            searchable={false}
                                            cleanable={false}
                                            className={input === 'flags' ? 'w75' : 'w150'}
                                            onChange={(e) => handleInput(e, input)}
                                            disabled={disabled}
                                        />
                                        {input === 'service' ?
                                            <p className='dns-input-arrow'><FaArrowCircleRight/></p> : null
                                        }
                                    </> :
                                    <>
                                        <Input
                                            onChange={(e) => handleInput(e, input)}
                                            type={['order', 'preference'].includes(input) ? 'number' : 'text'}
                                            min={0}
                                            max={65535}
                                            size='md'
                                            value={inputs[input]}
                                            disabled={disabled}
                                        />
                                        {input === 'replacement' ?
                                            <Whisper
                                                placement='top'
                                                trigger='hover'
                                                speaker={<Tooltip>{copy === inputs.replacement ? 'Скопійовано' : 'Скопіювати'}</Tooltip>}>
                                                <IconButton
                                                    appearance='ghost'
                                                    size='xs'
                                                    circle
                                                    onClick = {() => copyText(inputs.replacement)}
                                                    icon={<BiCopy/>}
                                                />
                                            </Whisper> : null
                                        }
                                    </>
                                }
                            </Badge>
                        </div>
                        {input === 'replacement' ?
                            <div className="empty"/> : null
                        }
                    </React.Fragment>
                )})
            }
            <div className={`dns-input dns-input-data`}>
                <Badge content='DNS data' color='blue'>
                    <Input
                        size='md'
                        type='text'
                        className={`${greenBorder ? 'green-bordered' : ''}`}
                        value={data}
                        disabled={true}
                    />
                </Badge>
            </div>
        </>
    )
}